<script setup lang="ts">
import { ref, computed, useAttrs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'
import { Preferences } from '@capacitor/preferences'
import { SUPPORTED_LOCALES } from '@/i18n'
import Icon from '@/components/partials/Icon.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'

const { locale, availableLocales, t } = useI18n()

defineOptions({
  inheritAttrs: false,
})

const attrs = useAttrs()

const countries = ref([
  {
    code: 'nl',
    country: t('countries.Netherlands'),
    selected: true,
    url: import.meta.env.VITE_BUILD_ENV === 'production' ? 'https://bistroo.nl' : 'https://staging.bistroo.nl',
  },
  ...(!__CAPACITOR__ ? [
    {
      code: 'be',
      country: t('countries.Belgium'),
      selected: false,
      url: import.meta.env.VITE_BUILD_ENV === 'production' ? 'https://bistroo.com/be' : 'https://staging.bistroo.com/be',
    },
  ] : []),
])

defineProps({
  small: { type: Boolean, default: false },
  extramargin: { type: Boolean, default: false },
})

const open = ref(false)

const languages = availableLocales.map(l => {
  const e = SUPPORTED_LOCALES[l]

  return {
    value: e.code,
    label: e.name,
  }
})

const router = useRouter()
const route = useRoute()

const store = useStore()

const currentCountry = computed(() => store.state.session.country)

async function selectLanguage(language: string) {
  const name = route.name?.split('___')[0]

  locale.value = language

  await Promise.all([
    store.dispatch('session/storeCurrentLocale', locale.value),
    router.replace({ ...route, name: `${name}___${locale.value}` }),
  ])

  open.value = false

  if (__CAPACITOR__) {
    await Preferences.set({ key: 'BISTROO_LOCALE', value: locale.value })
  }
}

function openCountry(country: string) {
  window.location.href = country
}

function toggle() {
  open.value = !open.value
}

const flag = computed(() => {
  const flag = locale.value === 'en' ? 'gb' : locale.value

  return `/assets/img/flags/${flag}.svg`
})
</script>

<template>
  <Button @click="toggle" v-bind="attrs">
    <img :src="flag" alt="Netherlands" :class="[ 'flag', { 'flag__small': small } ]" />
  </Button>

  <Transition>
    <div class="language-switcher" :class="[ { 'extramargin': extramargin } ]" v-show="open">
      <div class="language-switcher__wrapper">
        <div class="language-switcher__country">
          <div class="language-header">
            <span class="label">{{ t('terms.country') }}</span>
          </div>
          <div v-for="country in countries" :key="country.code">
            <button :class="{ 'selected': country.code === currentCountry }"
                    @click="openCountry(country.url)">
              <div class="select-option">
                <img :src="`/assets/img/flags/${country.code}.svg`" :alt="country.country" class="select-option__flag" />
                {{ country.country }}
              </div>
              <Icon name="check" class="check" v-if="country.code === currentCountry" />
            </button>
          </div>
        </div>
        <div class="language-switcher__seperator"></div>
        <div class="language-switcher__language">
          <div class="language-header">
            <span class="label">{{ t('terms.language') }}</span>
          </div>
          <div v-for="language in languages" :key="language.value">
            <button :class="{ 'selected': locale === language.value }" @click="selectLanguage(language.value)">
              <div class="select-option">
                {{ language.label }}
              </div>
              <Icon name="check" class="check" v-if="locale === language.value" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>

  <div class="dark-overlay" :class="{ 'active': open }"></div>
</template>

<style scoped lang="scss">
@import '@/assets/css/variables/variables.scss';
@import '@/assets/css/base/forms.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/breakpoints-down.scss';

.button {
  &::before {
    background: #ffffff;
  }
}

.flag {
  border-radius: 9999px;
  height: 2rem;
  width: 2rem;

  &__small {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@include lg-down {
  .dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 30;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      transition: opacity 200ms ease-in-out;
    }
  }
}

.button {
  @include lg-up {
    height: 100%;
  }

  &:deep(.button__content) {
    padding: 0;
    border: none;
  }
}

.language-switcher {
  padding: 0.5rem;
  z-index: 1000;

  position: absolute;
  top: calc(60px + env(safe-area-inset-top));
  right: 5vw;
  width: 90vw;
  height: auto;

  &.extramargin {
    top: 7rem;
    width: 96vw;
    right: 2vw;
  }

  @include lg-up {
    top: 5rem;
    right: 2rem;
    width: auto;
    height: auto;

    &.extramargin {
      top: 8rem;
      right: 5vw;
      width: auto;
    }
  }

  background: #fff;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  &__seperator {
    width: 1px;
    background: var(--color-neutral-primary);
    opacity: 0.1;
  }

  :deep(svg) {
    width: 1.5rem;
    height: 1.5rem;
  }

  .check {
    svg {
      fill: black;
    }
  }

  .language-header {
    margin-left: 1rem;
    margin-bottom: 0.8rem;
    font-weight: bold;
    color: var(--color-neutral-primary);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    width: 100%;
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    cursor: pointer;

    @include lg-up{
      min-width: 16rem;
    }

    .select-option {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &__flag {
        border-radius: 9999px;
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    color: var(--color-neutral-primary);
    font-weight: normal;

    &.selected {
      background: var(--color-neutral-light);
      font-weight: 600;
    }

    &:hover {
      background: #eee;
    }
  }

  &__locale {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
